import React, { Component } from 'react'
import { Helmet } from "react-helmet"

import "bootstrap/dist/css/bootstrap.min.css"

export class Layout extends Component {
  render() {
    return (
      <Helmet>
        <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
        <meta name="author" content="M8 Systems" /> 

        <link rel="shortcut icon" href="/assets-m8/images/favicon/M-favicon-16x16.png" />
        <link rel="shortcut icon" href="/assets-m8/images/favicon/M-favicon-32x32.png" />
        <link rel="shortcut icon" href="/assets-m8/images/favicon/M-favicon-48x48.png" />

        {/* Stylesheets*/}
        <link rel="stylesheet" href="/Package-HTML/style.css" type="text/css" />
        <link rel="stylesheet" href="/Package-HTML/css/font-icons.css" type="text/css" />
        {/* M8 Systems Stylesheet*/}
        <link rel="stylesheet" href="/assets-m8/css/colors.css" type="text/css" />
        <link rel="stylesheet" href="/assets-m8/css/style.css" type="text/css" />
        {/* <link rel="stylesheet" href="/assets-m8/css/font-icons.css" type="text/css" /> */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* Website Title*/}
        {/* <script id="mcjs">!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/35e3a0d6d0a28290de0242309/f7ff2893a78528157ff6b801c.js");</script> */}
        <title>M8 Systems</title>
      </Helmet>
    )
  }
}

export default Layout
