import React, { useLayoutEffect, useState } from "react"
import styled from "styled-components"

const StyledP = styled.p`
  margin-bottom: 0px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`

const StyledFooter = styled.footer`
  background-color: #333 !important;
  color: #ccc;
  border-top-color: rgba(0, 0, 0, 0.2) !important;
  padding-top: 1rem;

  a:hover {
    color: #cf3400 !important;
  }
`

const StyledContainer = styled.div`
  background-color: #222 !important;
  color: rgba(255, 255, 255, 0.75) !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);

  a {
    color: rgba(255, 255, 255, 0.75) !important;
  }

  a:hover {
    color: rgba(255, 255, 255, 0.75) !important;
  }
`

function Footer() {
  const m8EmailAddress = "sales@m8systems.com"

  const [showBr, setShowBr] = useState(() => {
    if (typeof window === "undefined") {
      return false
    }
    return window.innerWidth <= 768
  })

  useLayoutEffect(() => {
    if (typeof window === "undefined") {
      return
    }

    function handleResize() {
      if (window.innerWidth <= 768) {
        setShowBr(true)
        return
      }
      setShowBr(false)
    }

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <StyledFooter id="footer">
      <div className="container">
        {/* Footer Widgets*/}
        <div className="footer-widgets-wrap clearfix">
          <div className="row">
            <div className="col">
              <img
                className="footer-logo"
                src="/assets-m8/images/logo/m8-logo-color-horizontal-white.svg"
                height="23"
                width="200"
                alt="M8 Systems Logo in the footer"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <StyledP>
                {/* We believe in <strong>Intelligent</strong>,{" "}
                  <strong>Intuitive</strong> &amp; <strong>Cost Saving</strong>{" "}
                  Operational Standards. */}
                Empowering farmers by leveraging smart irrigation technology to
                reduce water and operational costs, while optimizing crop
                growth.
              </StyledP>
            </div>
            <div className="col-12 col-md-6">
              <abbr title="Email Address">
                <strong>Email:</strong>
              </abbr>
              <a href={`mailto: ${m8EmailAddress}`}>&nbsp; {m8EmailAddress}</a>
            </div>
          </div>
        </div>
      </div>
      {/* Copyrights*/}
      <StyledContainer id="copyrights">
        <div className="container clearfix">
          <div className="col_half">
            © {new Date().getFullYear()} M8 Systems, Inc.
            {showBr ? <br /> : " "}
            All Rights Reserved.
            <br />
            <div className="copyright-links">
              <a href="/terms">Terms of Use</a> /{" "}
              <a href="/privacy">Privacy Policy</a>
            </div>
          </div>
          <div className="col_half col_last tright">
            <div className="fright clearfix">
              <a
                className="social-icon si-small si-borderless si-facebook"
                title="Link to M8 Systems Facebook page"
                href="https://www.facebook.com/M8Systems/"
              >
                <i className="icon-facebook" />
                <i className="icon-facebook" />
              </a>
              <a
                className="social-icon si-small si-borderless si-linkedin"
                title="Link to M8 Systems Linkedin page"
                href="https://www.linkedin.com/company/m8systems/about/"
              >
                <i className="icon-linkedin" />
                <i className="icon-linkedin" />
              </a>
            </div>
          </div>
        </div>
      </StyledContainer>
    </StyledFooter>
  )
}

export default Footer
